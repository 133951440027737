.block-tradeshows{
  /* border:1px solid red !important; */
  background-color: #402b58 !important;
  padding:20px !important;
  .block-header{
    h3{
      color:white;
      padding-bottom: 40px;
      font-size:30px;
    }
  }
  .block-body{
    height: 460px;
    overflow: auto;


    .card{

      border: none;
      /* margin-bottom: 24px;
      margin-top: 24px; */
      margin-right: 8px;
      padding-top:34px;
      padding-bottom:34px;
      border-bottom: 1px solid #70588a;
      text-decoration: none;
      background-color: transparent;
      color:white;
      font-size:17px;
      //font-family: 'MyriadPro-Light';

      img{
        border-radius:9px;
      }
      h5{
        color:#e7beab;
        font-size:45px;
        font-family: 'Mont Regular';
      }
      .ms-3{
        margin-left: 1.7rem !important;
      }
    }

    .first{
      padding-top:0;
    }

    .last{
      padding-bottom:0;
      border-bottom:none;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #808080; // rgba(90, 90, 90);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent; // rgba(0, 0, 0, 0.2);
    }

  }
}