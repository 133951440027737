.page-tags{
  padding: 0;
  margin: 0;
  margin-bottom: 66px;

  ul{
    list-style: none;
    padding: 0;
    margin:0;

    li{

      display: inline-block;
      border-radius: 10px;

      padding: 8px;
      margin-right: 10px;
      margin-bottom: 10px;

      background-color: #f6e0d6;
      font-size: 18px;
      color: black;

      button {
        border: none;
        background-color: transparent;
        color: #442c5d;
      }
    }
  }
}

@media (max-width: 768px) {

  .page-tags{
    padding: 20px 20px 0 20px;
    margin: 0;
    margin-bottom: 0;

    ul{

      li{

        padding: 6px;


        margin-right: 6px;
      margin-bottom: 6px;

      }
    }
  }
}