.tooltip-image{

 /*  border:1px solid red; 
  max-width: 420px;
  width: 420px;
  /* background-color: blueviolet; */
  padding:0;
  margin:0;
  width: 296px;
  height: 238px;
  /* margin: 19px 27.2px 16.6px 172.2px;
  padding: 3px 19.8px 2.1px 0.8px; */
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.31);
  background-color: #442c5d;
  border-radius: 13px;

  .tooltip-inner{
    /* border:1px solid blue;
    background-color: blueviolet; */

    /* background-color: transparent;
    max-width: 420px;
    width: 420px; 
    width: 258px;*/
    width: auto;
    height: 184px;
    margin: 20px auto;
    padding: 21px 2px 14px 19px;
    border-radius: 31px;
    background-color: #442c5d;

    img{
      height: 164px;
      margin: -11px 12px 0 -20px;
      object-fit: contain; 
      width: auto;
      z-index: 0;
      border-radius: 20px;
      
    }
  }

}

.tooltip-image.show {
  opacity: 1;
}