.row-webinars{
  .col-2{
    margin-bottom: 20px;
  }
}

.card-webinar{

  background-color: transparent !important;
  border:none !important;

  text-align: center;


  a{
    height:140px;
    color:white;

  }

  a:hover{
    color:grey;
  }

  h5{
    font-size: 18px;
    margin-top:12px;

  }
  .card-thumb{
    height:140px;
    width:263px;
    background:#e9e9e9;

    background-size:cover;
    border-radius: 10px;

    .inner{
      height:100%;
      display: flex;
      justify-content: center;
      align-items:  center;
      flex-wrap: wrap;
    }
  }

  .time{
    font-size: 12px;
    color:grey;
  }

}

.card-view-all{
  /* border:1px solid red !important; */

  .card-thumb{
    .inner{
      flex-direction: column;
      font-size:19px;
      color:#fff;
      font-family:  'FrankRuhlLibre-Medium', serif;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;

      &:hover{
        color:#402b58 !important;
        background-color: #cccccc;
      }
    }

  }

}