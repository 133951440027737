.block-webinars{

  .block-header{

    display:flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: baseline;
    margin-right: 30px;

    h3.title{
      color:#402b58;
      padding-top:14px ;
      padding-bottom:14px ;
      padding-right:20px;
      /* border:1px soild red; */
      line-height: 48px;
    }

    a{
      color:#442c5d;
      font-size: 18px;
      //font-family: 'MyriadPro';
      &:hover{
        /* color: #402b58; */
        font-weight: bold;
        font-family: 'Mont Bold';
        //font-family: 'FrankRuhlLibre-Bold';
      }
    }

  }
  /* background-color: #c8c8c8 !important; */


   .card-view-all{
     font-size: 24px;

     a{
       text-decoration: none;
     }
     .card-thumb{
       background:#402b58;
     }
   }
 }

 .container-webinars{

    /* height:240px; */
    /* border:1px solid red; */
    overflow:hidden;
    //overflow-x: auto;
    //overflow-y: hidden;
    display: flex;
    flex-direction: row;
    justify-content:  space-between;
    margin-bottom:20px;


   /*  &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #808080; // rgba(90, 90, 90);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent; // rgba(0, 0, 0, 0.2);
    } */

    .webinar{
      /* border:1px solid green; */
      width:263px;
      /* background-color: white; */
      /* border-radius: 15px; */
      /* min-width: 230px; */
      /* height: 110px; */
      margin-right:30px;
      font-size: 21px; // 1.5em;
      /* padding:12px; */
    }
}
