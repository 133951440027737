.ff-mont{
  font-family: 'Mont Regular';
}

.ff-mont-black{
  font-family: 'Mont Black';
}

.ff-mont-bold{
  font-family: 'Mont Bold';
}

.ff-mont-light{
  font-family: 'Mont Light';
}

.fs-15px{
  font-size:15px;
}

.fs-17px{
  font-size:17px;
}

.fs-18px{
  font-size: 18px;
}

.fs-39px{
  font-size:39px;
}

.text-pink{
  color: #ffbd98;

}

.text-purple{
  color: #442c5c;
}