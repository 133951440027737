
.modal-drawer{
  border-left:3px solid black;
  border:none;
  /* border:10px solid red; */
  /* width:90%; */
  /* right:0px;*/
  height:100%;
  background-color: white;
  min-width: 730px;
  margin: 0 0 0 auto !important;

  .modal-content{
    border:none;
  }

  .modal-header{

    //background-color: #e2e2e2;
    background-color: #402b58;
    align-items:flex-start;
    color:white;


    .time{
      display: inline-block;
    }

    .small-text{
      font-size:12px;
      color:grey;
    }

    .media-modal{
      flex-direction: row;
    }

    button.close{
      border:none; //1px solid grey;
      border-radius: 12px;
      height:26px;
      width:26px;
      padding: 0;
      background-color: white;


      span{
        font-size:20px;
        font-weight: bold;
      }

      .sr-only{
        display: none;
      }
    }

    .-media-body {
      margin-top: 27px;
    }

  }

  .modal-body{

    .card-button{
      width:100%;
      border:none;
      outline: none;
      text-align: left;
      padding: 0;
      margin: 0;
      background-color: transparent;
      cursor: pointer;
    }


    .card{
      margin-bottom:10px;
      border:none;
      border-bottom:1px solid #D9D9D9;
      border-radius: 0;
      flex-direction:row;
      font-size: 20px;

      .card-body{
        padding:12px 8px;
        font-size: 18px;
        .language-chips .btn-circle {
          background-color: #232323;
          color:white;
          padding: 3px 10px 0;
          /* padding-right:10px;
          padding-left:10px; */
          border-radius: 12px;

        }
      }

      .card-actions{
        vertical-align: middle;
        align-self:center;
      }

      .btn-download{
        display: none;
        color:#2c2c2c;
      }

      &:hover{
        background-color: #f2f2f2;
        border-radius: 10px;
        border-color:transparent;

        color:#20858a;

        .btn-download{
          /* border:1px solid red; */
          display: inline-block;
          text-transform: uppercase;
        }

        .language-chips .btn-circle {
          background-color: #20858a;
        }
      }
    }
  }

  .modal-image{

    img{
      width: 150px;
      height: 150px;
      border-radius: 10px;
    }

    .placeholder{
      width: 150px;
      height: 150px;
      border-radius: 10px;
      background-color: #8b8b8b;

    }

    @media (max-width: 576px) {

      img{
        width: 100px;
        height: 100px;
      }

      .placeholder{
        width: 100px;
        height: 100px;
        border-radius: 10px;
        background-color: #8b8b8b;

      }

    }
  }

  .edit-link{
    text-align:right;

    @media (max-width: 768px) {
      padding-top:1rem;
      text-align:left;
    }
  }

}

@media (max-width: 768px) {

  .modal-body{
/*
    .card-button{
      width:100%;
      border:none;
      outline: none;
      text-align: left;
      padding: 0;
      margin: 0;
      background-color: transparent;
      cursor: pointer;
    } */


    .card-mobile{
      margin-bottom:10px;
      border:none;
      border-bottom:1px solid #D9D9D9;
      border-radius: 0;
      flex-direction:row;
      font-size: 20px;

      .card-body{
        padding:12px 8px;
        font-size: 18px;
        .language-chips .btn-circle {
          background-color: #232323;
          color:white;
          padding: 3px 10px 0;
          /* padding-right:10px;
          padding-left:10px; */
          border-radius: 12px;

        }
      }

      .card-actions{
        vertical-align: middle;
        align-self:center;
      }

      .btn-download{
        display: inline-block;
        color:#2c2c2c;
        text-transform: uppercase;
      }

      /* &:hover{
        background-color: #f2f2f2;
        border-radius: 10px;
        border-color:transparent;

        color:#20858a;

        .btn-download{
          display: inline-block;
          text-transform: uppercase;
        }

        .language-chips .btn-circle {
          background-color: #20858a;
        }
      } */
    }
  }

}



@keyframes modalFade {
  from {transform: translateX(100%); opacity: 1;}
  to {transform: translateX(0);opacity: 1;}
}

.modal {
  animation-name: modalFade;
  animation-duration: .5s;
}

.modal-backdrop.show {
  opacity: 0.75 !important;
}


@media (max-width: 768px) {
  .modal-drawer{
    min-width: 100vw;

    .card-mobile{
      margin-bottom:0;
    }

    .modal-body{
      padding: 0;
    }
  }
}