.block-in-the-media{
  padding:20px !important;
  .block-header{
    h3{
      padding-bottom: 40px;
      font-size:30px;
    }
  }
  .block-body{
    height: 460px;
    overflow: auto;

    .card{
      border:none;
      margin-bottom: 20px;
      //padding-bottom: 20px;

      img{

        border-radius: 9px;
        box-shadow: 5px 7px 20px 0 rgba(0, 0, 0, 0.29);
      }

      a{
        text-decoration: none;
        color:black;


      }

      .LinesEllipsis{
        font-size: 17px;
      }
    }


    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #808080; // rgba(90, 90, 90);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent; // rgba(0, 0, 0, 0.2);
    }

  }


}