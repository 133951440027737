// @import url("https://use.typekit.net/cjh6nvu.css");
// @import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre&display=swap');
// font-family: 'myriad-pro';
// font-family: 'Frank Ruhl Libre';

body{
  background-color: #ebe9e8; // RGB(194,194,194);
  font-family: 'Mont Regular'; // MyriadPro, sans-serif;
  //font-family: 'Frank Ruhl Libre', sans-serif;
  font-style: normal;
  font-weight: normal;
}

.bg-purple{
  background-color: #442c5d;
}

.color-purple{
  color: #442c5d;
}

.color-grey65{
  color: #656565;
}

.bg-grey{
  background-color: #ebe9e8;
}

#header{
  background: white;
}

#footer{
  background: linear-gradient(to bottom, #505050 0%, #666666 100%);
  min-height:100px;


  p{
    color:#b4b4b4;
  font-size:11px;
  }
  a{
    color:#adaaaa;
    font-size:14px;
  }
}

section.user-menu{

  /* border:1px solid red; */
  //background-color:linear-gradient(to bottom, #444444 0%, #666666 100%);
  background-color: #444444;
  /* margin-bottom:3em; */
  color:white;
  .navbar {
    padding-top:0;
    padding-bottom:0;
  }
}

.main-container{
  /* margin:3em auto; */
  margin:0;
  padding:0; // 0 1.0875rem 1.45rem;

}


/* Style page content */
main {
  margin-left: 290px; /* Same as the width of the sidebar */
  padding: 0px; // 10px;
}


.container-app{
  padding-top:20px;
  margin:0;
  padding:40px;
}

.container-app-2{

  margin:0;
  padding:40px;
  /* padding-top:15px; */
}

.disclaimer{
  
  background-color:  rgba(0, 0, 0, 0.8);
  
  position: fixed;
  bottom: 0;
  width: calc(100% - 290px);

  .disclaimer-container {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px;

  }
  .disclaimer-text {
    color: white;
    width:100%;
  }
  button{
    display: inline-block;
    background-color: transparent;
    padding:6px;
    outline:none;
    border:none;
    color:white;
    font-size: 16px;
  }
}

.disclaimer-inner{
  
  background-color:  rgba(0, 0, 0, 0.8);
  margin-top: 1rem;
  width: max-content;

  .disclaimer-container {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px;

  }
  .disclaimer-text {
    color: white;
    width:100%;
  }
}

@media (max-width: 768px) {
/*
  .container-app{
    padding: 0;
  } */

  .history-file-table{
    padding:0 !important;
  }
  .container-app-2{

    margin:0;
    padding:0;
    /* padding-top:15px; */
  }

  .disclaimer{
    width: 100%;
  }

}

.language-links{
  .btn-circle{
    color: #fff;
    background-color: rgb(68, 44, 76);
   // #0d6efd;
    border-color:rgb(68, 44, 76);
    border-radius: 30px;
  }
}

.language-chips{
  .btn-circle{
    font-size:13px;
    font-weight: 600;
    letter-spacing: 0.44px;
    color: #35302f;
    background-color: #ebe9e8;
   // #0d6efd;
    /* border-color: #ebe9e8; */
    border-radius: 13px;
    padding: 2px 3px ;
    margin-right: 6px;
    min-width:32px;
    cursor:auto;
  }
}

.contianer-public{

  background-color: white;
  border-radius: 20px;
  padding: 20px;
}





.btn-navbar{
  /* border:2px solid red; */
  background-color: white;
  border-radius: 19px;
  padding: 4px 6px;
  margin: 0;
  outline: none;
  width:54px;
  height:49px;
  vertical-align: middle;
  line-height: 2.4;
  img{
    vertical-align: middle;
  }
  button{
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    color: #3a3a3a;
    text-transform: uppercase;
    border-left: 1px solid #d8d8d8;
    padding-left: 1rem;
    @media (max-width: 576px) {
      float: right;
    }
  }

  input{
    margin: 0;
    padding: 0;
    border:none;
    width: 252px;

    &::placeholder{
      color: #a5a5a5;
      opacity: 1;
    }
    @media (max-width: 435px) {
      width: 177px;
    }

  }
}


.btn-navbar-search{
  width:auto;
  height:49px;
  padding: 6px;
  @media (max-width: 576px) {
    margin: 0rem 0 1rem;
    width:100%;
  }
}


.btn-navbar-mid{
  border-radius: 0px;
  margin-right: 1px;
  margin-left: 1px;
}

.btn-navbar-left{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-navbar-right{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* The sidebar menu */

.btn-green{
  background-color:  #302e2f;
}

.btn-star{
  border: none;
  outline: none;
  background: none;
}

.container-files{
  background-color: white;
  /* border-radius: 20px; */
  /* padding: 20px; */
  margin: 0;
}


.container-page-header{

  padding:40px 40px 20px 40px;

  .container-page-header-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
  }
}

.container-header{

  //padding:20px 40px 40px 40px;
  //height:180px;
  padding:20px 40px 20px 40px;
  background-color: #e2e2e2;

}

.btn-notifications{
  position: relative;
  .notifictions-number{

    height:12px;
    width:12px;
    position: absolute;
    top:9px;
    right:8px;
    border-radius: 6px;
    background-color: red;
    border:2px solid white;

  }
}

.list-files{
  .table{
    button{

      border:none;
      outline:none;
      background:none;
    }
  }
}



button.close{
  border:none; //1px solid grey;
  border-radius: 12px;
  height:26px;
  width:26px;
  padding: 0;
  /* background-color: white; */

  span{
font-size:20px;
    font-weight: bold;
  }

  .sr-only{
    display: none;
  }
}

.icon-flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

.btn-logout{
  /* border: none; */
  color:white;
  font-size: 18px;
  display: inline-block;
  padding-top: 0;

  &:hover{

    color:white !important;
  }
}

.admin-panel{
  text-align: right;

  a{
    padding-left:12px;
  }
}


.login-block{

  width:420px;
  margin-top:90px;
  background-color: #432d5c;
  padding:20px 10px;
  position: relative;

  h2{
    //font-family: 'FrankRuhlLibre-Medium';
    //font-family: 'Mont Regular';
    font-size:33px;
    margin: 15px 0 44px;
  }

  .btn{
    background-color: #eec7b1;
    border-radius: 40px;
    border:none;
    color:black;
    font-size: 22px;
  }
  .btn:hover{
     /* background-color:lighten(#eec7b1,75%); */
     filter: brightness(115%);

  }
  label{
    font-size: 23px;
    margin-bottom: 0;
    margin-left:6px;
  }
  input{
    height: 60.9px;
    margin: 4px 33px 31px 3px;
    border-radius: 15px;
    background-color: #ffffff;
  }
  .forgot-pass{
    color: #fff;
    margin: -20px 0 30px 6px;
    display: block  ;
    font-size:17px;
  }
  .crem {
    color: #ffbd98;
    font-size: 47px;
    font-family: 'Mont Bold';
    line-height: 50px;
  }
  .white {
    color: #ffffff;
    font-size: 47px;
    font-family: 'Mont Light';
  }
  .login-logo {
    position: absolute;
    top: -55px;
  }
}



.register-block{
  width:600px;
  text-align: center;

  h5{
    //font-family: "FrankRuhlLibre-Medium";
    font-size: 31px;
    //font-weight: 500;
    margin-top:50px;
  }

  p{
    //font-family: "MyriadPro-Light";
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 32px;

  }

  a.btn{
    width:400px;
    background-color: #ffffff;
    border-radius: 40px;
    border :2px solid #442c5d;
    color:#442c5d;
    font-size: 22px;
    margin-top: 20px;
  }
}



.forget-block{
  /* border:1px solid red; */
  text-align: center;

  h2{
    //font-family: "FrankRuhlLibre-Medium";
    font-size: 31px;
    color:#442c5d;
    font-weight: 500;
    margin-top:68px;
  }


  .logo-wide{
    padding-bottom: 30px ;
  }

}

.recovery-block{
  /* border:1px solid red; */
  width:560px;
  //text-align: left;

  .container-fluid, .container{
    padding: 0;
  }

  h1{
    //font-family: "FrankRuhlLibre";
    font-size:39px;
    color:white;
  }

  .content{
    width:430px;
  }

  p{
    color:white;
    //font-family: "MyriadPro-Light";
    font-size:20px;
  }

  .btn{
    padding-left: 0;
    margin-left: 0;
    background-color: #eec7b1;
    border:none;
    border-radius: 40px;
    color:black;
    font-size:22px;
  }

  .form-label{
    color:white;
    font-size:26px;
  }

  input.form-control {
    /* border:1px solid red; */
    line-height: 50px;
    font-size:22px;
    border-radius: 19px;
  }
/*
  #username_or_emailInput{
    margin-bottom: 28px;
  } */


  .btn-wide{
    margin-top: 28px;
  }
}

.grid-5{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 30px;


}





.password-reset-block{
  /* border:1px solid red; */
  width:560px;
  text-align: left;

  .container-fluid, .container{
    padding: 0;
  }

  h1{
    //font-family: "FrankRuhlLibre";
    font-size:41px;
    color:white;
  }

  h2{
    /* font-family: "FrankRuhlLibre"; */
    font-size:32px;
    color:white;
  }

  p{
    color:white;
    //font-family: "MyriadPro-Light";
    font-size:22px;
  }

  .btn{
    padding-left: 0;
    margin-left: 0;
    background-color: #eec7b1;
    border:none;
    border-radius: 19px;
    color:black;
    font-size:22px;
  }

  .form-label{
    color:white;
    font-size:26px;
  }

  input.form-control {
    /* border:1px solid red; */
    line-height: 50px;
    font-size:22px;
    border-radius: 19px;

    /* margin-bottom: 12px; */
  }

  .container-field{
    /* line-height: 50px;
    font-size:22px;
    border-radius: 19px; */

    margin-bottom: 12px;
  }

  .btn-wide{
    margin-top: 28px;
  }
}



.password-set-block{
  /* border:1px solid red; */
  width:560px;
  text-align: left;
  margin:40px;

  .container-fluid, .container{
    padding: 0;
  }

  h1{
    //font-family: "FrankRuhlLibre";
    font-size:41px;
    /* color:white; */
  }

  h2{
    /* font-family: "FrankRuhlLibre"; */
    font-size:32px;
    color:white;
  }

  p{
    color:white;
    //font-family: "MyriadPro-Light";
    font-size:22px;
  }

  .btn{
    padding-left: 0;
    margin-left: 0;
    background-color: #eec7b1;
    border:none;
    border-radius: 19px;
    color:black;
    font-size:22px;
  }

  .form-label{
    /* color:white; */
    font-size:26px;
  }

  input.form-control {
    /* border:1px solid red; */
    line-height: 50px;
    font-size:22px;
    border-radius: 19px;

    /* margin-bottom: 12px; */
  }

  .container-field{
    /* line-height: 50px;
    font-size:22px;
    border-radius: 19px; */

    margin-bottom: 12px;
  }

  .btn-wide{
    margin-top: 28px;
  }
}



.password-change-block{
  /* border:1px solid red; */
  /* width:560px; */
  /* width:100%; */
  text-align: left;
  margin:10px;

  .container-fluid, .container{
    padding: 0;
  }

  h1{
    //font-family: "FrankRuhlLibre";
    font-size:41px;
    /* color:white; */
  }

  h2{
    /* font-family: "FrankRuhlLibre"; */
    font-size:32px;
    color:white;
  }

  p{
    color:white;
    //font-family: "MyriadPro-Light";
    font-size:22px;
  }

  .btn{
    padding-left: 0;
    margin-left: 0;
    background-color: #eec7b1;
    border:none;
    border-radius: 19px;
    color:black;
    /* font-size:22px; */
  }



  input.form-control {
    /* border:1px solid red; */
    /* line-height: 50px;
    font-size:22px; */
    border-radius: 19px;

    /* margin-bottom: 12px; */
  }

  .container-field{
    /* line-height: 50px;
    font-size:22px;
    border-radius: 19px; */

    margin-bottom: 12px;
  }


}

fieldset.change-password{
  border: 1px solid #212529;
  padding-left:20px;
  legend{
    float:none;
    /* background: transparent; */
    padding-left:6px;
    padding-right:6px;
    width:auto;
  }
}


.main-404{
  background-color: #442c5d;
  min-height: 100vh;

  main{
    margin:0;
    padding-top:100px;
    padding-bottom:100px;

  }

  .pic-left{
    background-image: url('/images/404/left.png');
    min-height:648px;
    background-repeat: no-repeat;
    background-position: right;
    overflow: hidden;
    background-size: cover;

    @media (max-width: 991px) {
      visibility: hidden;
      min-height:auto;
    }
  }

  .pic-right{
    background-image: url('/images/404/right.png');
    min-height:648px;
    background-repeat: no-repeat;
    background-position: left;
    overflow: hidden;
    background-size: cover;

    @media (max-width: 991px) {
      visibility: hidden;
      min-height:auto;
    }
  }

  .Ooops{
    font-size: 115px;
    letter-spacing: -1.15px;
    text-align: center;
    color: #eec7b1;
  }

  .text1{
    color:white;
    letter-spacing: -1.15px;
    font-size: 30px;
    text-align: center;
  }

  .text2{
    color:white;
    letter-spacing: -1.15px;
    font-size: 20px;
    text-align: center;
  }

  .btn-search{
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    a{
      //font-family: 'MyriadPro';
      text-align:center;
      font-size: 22px;
      color:black;
      background-color: #eec7b1;
      border: none;
      padding:20px;

      width: 316.6px;
      border-radius: 40px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
  }


  .response-code{
    text-align:center;
    color:#2b183e;
    font-size: 64px;
  }
}


.btn-tutorial{

  margin-left:6px;
  width: 110px;
  .content{
    display: flex;
    justify-content: space-between ;
    align-items: center;
  }


  span{
    max-width:60px;
    /* border:1px solid red;*/
    display: inline-block;
    font-size: 15px;
    //font-family: 'MyriadPro';
    color:'#3a3a3a';
    line-height: 17px;;
  }
}

div.tutorial-link{
  padding:0;

  .btn-tutorial{
    background: none;
    color:white;
    margin-left: 0;
    padding-left: 0;
    width: auto;
    span{
      max-width:200px;
      margin-left:10px;
      //font-family: 'MyriadPro-Light';
      font-size: 16px;
      line-height: 39px;
    }
    &:hover{
      text-decoration: underline;
      span{
        text-decoration: underline;
      }
    }
  }
}

.firefox-notice{
  border:1px solid #eec7b1;
  border-radius: 10px;
  padding:10px;
  margin-bottom: 20px;
  position: relative;
  input{
    height:1em;
    margin:auto;
  }

  label{
    font-size: 16px;
  }

  .btn{
    position: absolute;
    right:10px;
    top:10px;
    background-color: white;
    font-size: 12px;
    padding:4px;
  }
  button{

  }

  a{
    color:white;
    text-decoration: underline;
  }
}

@media (max-width: 768px) {


  .language-chips{
    .btn-circle{
background-color: white;
vertical-align: bottom;
padding-top: 4px;
    }
  }

}
@media (max-width: 576px) {

  main {
    margin-left: 0;
  }

  .sidenav{
    display: block;
    width:auto;
    position: relative;
    height: auto;
  }

  .container-page-header{
    padding: 20px 20px 20px 20px;


  }

  .container-app{
    padding:20px;
  }

  .register-form{
    .container-fluid{
      padding:0;
    }
    .container{
      padding:0;
    }
  }
  .login-block{

    width:100%;
    padding-top:20px;
    padding-bottom:20px;

    h2{ font-size:31px; }
  }

  .register-block{

    width:100%;
    padding-top:20px;
    padding-bottom:30px;

    img{ width:80%; }
    a.btn{ width: 100%; }
  }

  .forget-block{
    img.decoration{ width:80%; }
    h2{ margin: 16px 0 16px 0}

  }

  .recovery-block{
    padding:10px 10px 20px 10px;
    width:100%;
    h1{ font-size:32px; }
    .content{      width:100%;    }
  }

  .password-reset-block{
    padding:10px 0 20px 0;
    width:100%;
    h1{ font-size:32px; }
  }


  .container-header{

    padding:20px 20px 20px 20px;
   /*  height:220px; */
   height:auto;


  }



  a.logo-dark{
    .word-partner{ font-size: 24px;}
    .word-zone{ font-size: 24px; padding-left:10px}
  }

  .col.header-nav {
    padding-bottom: 3rem;
  }

}

.card-notification{
  .remove-button{
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.row-webinars{

  .card-webinar{
    .card-thumb{
      width:240px;
    }
  }
}

.videos{
  a{
    color: #424c5c;
/*     font-size: 21px; */
  }
}

@media (max-width: 991px) {

  main {
    margin-left: 0;
  }

  .sidenav{
    display: block;
    width:auto;
    position: relative;
    height: auto;
  }

  .container-page-header{
    padding: 20px 20px 20px 20px;


  .container-page-header-grid{
    grid-template-columns: 1fr;
  }

  }

  .container-app{
    padding:20px;
  }

  .register-form{
    .container-fluid{
      padding:0;
    }
    .container{
      padding:0;
    }
  }

}


.show-desktop{ display: block; }
.show-mobile{  display: none;}

@media (max-width: 768px) {
.show-desktop{  display: none;}
.show-mobile{  display: block;}

}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.registration-success{

  background-color: #402b58;
  color:white;

  .bg-image{


    background-image: url('/images/registration/success-desktop.png');
    background-size:cover;
    background-repeat: no-repeat;
    background-position:center center;

    width:1500px;
    height: 857px;

    text-align: center;

    .text{

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      margin:auto;
      width: 560px;
      font-size: 22px;
      height:100%;

      h2{
        font-size:115px;
        //font-family: FrankRuhlLibre-Bold;
        color: #eec7b1;
      }

      h3{
        font-size:32PX;
      }

      p{

      }

      .btn{
        background-color: #eec7b1;
        color:black;
        padding: 18px 44px;
        border-radius: 20px;
        width:316px;
        border:none;
      }
    }
  }

  .mobile-text{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin:auto;
    width:100%;
    font-size: 16px;
    height:100%;
    padding: 0 8px;

    h2{
      font-size:70px;
      //font-family: FrankRuhlLibre-Bold;
      color: #eec7b1;
      padding: 0;
      margin: 0;
    }

    h3{
      font-size:25px;
    }

    .btn{
      background-color: #eec7b1;
      color:black;
      padding: 18px 44px;
      border-radius: 20px;
      width:316px;
      border:none;
    }

  }
}


main.main-iframe{
  margin-left: 0;
  height: 90vh;
  margin: 0;
  padding: 0;
  max-height: 100%;
}

iframe.iframe{
  display:block;
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
}