.slider{

  /* border:1px solid red; */
  height:140px;
  display: flex;
  flex-direction: row;
  align-items:center;
  position: relative;

  button{

    /* padding:10px; */
    border-radius: 22px;
    border: none;
    outline: none;
    height: 44px;
    width: 44px;
    box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.16);
    text-align: center;

    &:focus {
      outline: 0 !important;
    }

  }

  .slides{
    /* border:2px solid magenta; */
    /* height:140px; */
    width:100%;
    display: flex;
    flex-direction: row;
    //flex-wrap: nowrap;
    overflow: hidden;
    //overflow-x: auto;
    justify-content: space-between;
    margin:0 10px;

    //transition: .6s ease;
    //margin:0 10px;

    .slide{
      //width:230px !important;
      //min-width: 230px;
      //height:140px;
      //border: 1px solid grey;
      //margin: 0 10px;
      //transition: .6s ease;
    }

    .category-level-1{
      background-color: white;
      border-radius: 15px;
      min-width: 230px;
      height: 110px;
      margin-right:30px;
      font-size: 20px; // 1.5em;
      padding:12px;

      a, div{
        color:black;
        text-decoration: none;

        width: 100%;
        height: 100%;
        display: block;

        .filesNumber{
          font-size:12px;
          display:block;
          color:#888888;
        }
      }

    }

    .category-level-1-active{

       border:1px solid black;
       background-color: #402b58;

       a{
          color:#b5b5b5;
          color:white;

       }

    }

    .category-level-1-disabled{

      /*  border:1px solid black; */
      background-color: #cacaca;
      color: #949494;
      div{
        color: #949494;
        .filesNumber{
          /* color:#2a2a2a; */
          color: #949494;
        }
      }
    }

    .category-level-1-last{
      margin-right: 0;
    }
  }

  .left-grad{
    position: absolute;
    top:10;
    left:54px;
    /* border:1px solid red; */
    height: 112px;
    width: 40px;
    background-image: linear-gradient(to right, #e2e2e2, transparent);
  }

  .right-grad{
    position: absolute;
    top:10;
    right:54px;
    /* border:1px solid green; */
    height: 112px;
    width: 40px;
    background-image: linear-gradient(to left, #e2e2e2, rgba(226, 226, 226, 0));
  }
}

.slider-corporate{

  .slides{

    justify-content: flex-start;

    .category-level-1{
      margin-right:24px;
      max-width: 240px;
    }
  }
}