
.card-video{

  background-color: transparent !important;
  border:none !important;

  text-align: center;


  a{
    height:140px;
    color:white;

  }

  a:hover{
    color:grey;
  }

  h5{
    font-size: 18px;
    margin-top:12px;
    width:263px;
  }

  .card-thumb{
    height:140px;
    width:263px;
    background:#e9e9e9;

    background-size:cover;
    border-radius: 10px;

    .inner{
      height:100%;
      display: flex;
      justify-content: center;
      align-items:  center;
      flex-wrap: wrap;
    }
  }

  .time{
    font-size: 12px;
    color:grey;
  }

}
