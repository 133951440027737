





.files-header{

  font-size: 30px;
  color: #a8a8a8;
  /* margin-top: -10px; */

  h1{
    display: inline-block;
    font-size: 40px;
    color: #35302F;
    font-weight: normal;
    letter-spacing: -0.81px;
    line-height: 1.07;
    font-family: 'Mont Bold';
  }
  h2{
    display: inline-block;
    font-size: 30px;
    color: #35302f;
    font-weight: normal;
  }
}

.dadida{

  overflow:auto;
  height: 30px;

  div{
    display: inline;
  }
}

.categories-level-2-container{
  .filter-by{
    font-size: 18px;
    margin-right:20px;
    line-height: 24px;
  }

  #dropdown-basic{
    box-shadow:none;
  }

  .dropdown{
    display: inline-block;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;


    .dropdown-toggle{
      border:none;
      background-color:transparent;
      border:1px solid #747474;
      border-radius: 16px;
      padding:8px;


      &::after{
        margin-left:12px;
        margin-right:12px;
        vertical-align: middle;
        background-image: url('../../images/Polygon.png');
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        border:none;
        width:17px;
        height:14px;



      }

      .sperator{
        padding-left:10px;
        border-right:1px solid #e9e9e9;
      }
    }

    .btn{
      font-size: 18px;

    }
    .dropwdown-item{
      font-size: 20px;

    }

    .grey{
      color: #b4b4b4;
    }

  }
}

@media (max-width: 576px) {
  .files-header{
    font-size: 26px;
    h1{
      font-size: 26px;
    }
    h2{
      font-size: 26px;
    }
  }

  .categories-level-1-container{
    flex-direction:column;
    height:auto;

    .category-level-1 {

      border-radius: 9px;

      height: 80px;
      margin-right: 0;
      font-size: 17px;
      padding: 12px;
    }

  }
}