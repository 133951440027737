.row-videos{
  .col-3{
    margin-bottom: 20px;
  }

  button{
    border: none;
    outline: none;
    padding: 0;

    &:focus{
      border: none;
      outline: none;
    }
  }
}
