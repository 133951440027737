@font-face {
  font-family: 'MyriadPro' ;
  src: local('MyriadPro'), url("../fonts/MyriadPro/MyriadPro-Regular.otf") format("opentype");
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'MyriadPro-Light' ;
  src: local('MyriadPro-Light'), url("../fonts/MyriadPro/MyriadPro-Light.otf") format("opentype");
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'MyriadPro-Semibold' ;
  src: local('MyriadPro-Semibold'), url("../fonts/MyriadPro/MyriadPro-Semibold.otf") format("opentype");
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
}


@font-face {
  font-family: 'MyriadPro-BoldIt' ;
  src: local('MyriadPro-BoldIt'), url("../fonts/MyriadPro/MyriadPro-BoldIt.otf") format("opentype");
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
}





@font-face {
  font-family: 'FrankRuhlLibre'/* , serif  */;
  src: local('FrankRuhlLibre'), url("../fonts/Frank Ruhl/FrankRuhlLibre-Regular.ttf") format("truetype");
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
}


@font-face {
  font-family: 'FrankRuhlLibre-Bold'/* , serif  */;
  src: local('FrankRuhlLibre-Bold'), url("../fonts/Frank Ruhl/FrankRuhlLibre-Bold.ttf") format("truetype");
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'FrankRuhlLibre-Medium'/* , serif  */;
  src: local('FrankRuhlLibre-Medium'), url("../fonts/Frank Ruhl/FrankRuhlLibre-Medium.ttf") format("truetype");
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Mont Regular'/* , serif  */;
  src: local('Mont Regular'), url("../fonts/Mont/Fontfabric - Mont Regular.otf") format("truetype");
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Mont Bold'/* , serif  */;
  src: local('Mont Bold'), url("../fonts/Mont/Fontfabric - Mont Bold.otf") format("truetype");
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Mont Black'/* , serif  */;
  src: local('Mont Black'), url("../fonts/Mont/Fontfabric - Mont Black.otf") format("truetype");
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Mont Light'/* , serif  */;
  src: local('Mont Black'), url("../fonts/Mont/Fontfabric - Mont Light.otf") format("truetype");
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
}