.btn-light{
  margin-top: 10px;
}

.share-copy{

  border:none;
  padding:6px 8px;
  background-color: white;
  border-radius: 12px;
  margin-top: 10px;
  margin-right: 20px;
  display: flex;
  justify-content:  space-between ;

  input{
    border:none;
    outline: none;
    /* display:inline-block; */
    width: 70%;
    color:grey;
    margin-top: 2px;
  }

  button{
    border: none;
    outline: none;
    background: none;
    text-decoration: underline;
  }
}