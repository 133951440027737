

.modal-90w{

  width:96% !important;
  max-width:96% !important;



  .col-tags{
    background-color: #432d5c;
    text-align: center;

    img{
      padding-top:60px;
    }

    .btn-close-modal{
      position: absolute;
      right:20px;
      top:20px;
      font-size:20px;
    }

    h3{
      color: white;
      text-align: center;
      font-size:39px;
    }

    .show-results{
      /* position: absolute;
      bottom:20px;
      left:auto;
      right:auto;
      width:400px; */
    }
  }

  .col-controls{
    background-color: #efefef;

    h1{
      padding:0;
      font-size: 40px;
    }

    h3{
      font-size: 20px;
    }

    .keywords{
      font-size: 20px;
      display: flex;
      align-items: baseline;

      input{

        border-radius: 11px;
        font-size: 22px;
        margin-left:12px;
        padding: 4px 6px;
        width: 100%; // 653px;
        height: 49px;
        border-radius: 11px;
        border: solid 1px #b9b9b9;
        background-color: #fff;
      }
      .help{
        margin: 11px 59px 39px 3%;
        //font-family: MyriadPro;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: 0.32px;
        text-align: left;
        color: #9d9d9d;
      }
    }
  }



  .categories-list{

    background-color: white;
    padding:6px;

    border:1px solid #dedede;
    border-radius: 10px;

    .categories-list-inner{

      height:400px;
      overflow:hidden;
      overflow-y:scroll;
      padding:6px;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: #d1d1d1; // rgba(90, 90, 90);
        border-radius: 5px;
      }

      &::-webkit-scrollbar-track {
        background: transparent; // rgba(0, 0, 0, 0.2);
      }


      button{
        border:none;
        background:transparent;
        width:100%;
        text-align: left;
        white-space: nowrap;
        margin-bottom: 8px;
        border:1px solid transparent;
        font-size: 15px;

        &.active{
          /* border:1px solid red; */
          background:#2a2a2a;
          color:white;
          border-radius: 10px;
        }
      }

    }

    .categories-list-short{
      height:200px;
    }
  }



  ul.tags{
    list-style: none;
    margin: 20px 0 30px 0;
    padding: 0;
    text-align: center;

    li{
      /* font-size: 16px; */
      display: inline-block;
      /* border:1px solid red; */
      border-radius: 10px;
      padding: 8px;
      margin-right: 10px;
      margin-bottom: 10px;
      /* background-color: #dbdbdb; */
      background-color: #f6e0d6;
      //height:36px;
      font-size:17px;
      color:black;

      button{
        border:none;
       /*
       padding: 0;
        margin: 0;
        */
        background-color: transparent;
        color:#442c5d;

        svg {
          margin-top: -4px;
        }


      }
    }

  }

  .show-results{

    text-align: center;
    margin:auto;


    button{
      width: 50%;
      border: none;
      background-color: white;
      border-radius: 40px;
      /* height:40px; */
      line-height: 3;
      font-size:22px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      padding-top: 2px;
    }
  }
}

.modal-search{
  .modal-content{
    .modal-header{
      display: inline;
      border:3px solid red;
      button{
        display: inline;
      }
    }
    .modal-body{
      padding: 0;

      .col-6{
        padding:10px;

      }
    }
  }
}

.modal-backdrop.show {
  opacity: 0.75 !important;
}

button.advanced-back{

    height: 42px;
    margin: 0 30px 0 0;
    padding: 2px 20px 0 20px;
    border-radius: 13px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #442c5d;
    vertical-align: middle;

    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: 0.85px;
    text-align: left;
    color: #fff;

    border:none;
    outline:none;
}

button.advanced-back:focus {
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

@media (max-width: 768px) {
  .modal-90w{
    width:100% !important;
    max-width:100% !important;

    &.modal-dialog{
      margin: 0;
    }

    .col-controls{

      h1{
        font-size: 30px;
      }
      .container-fluid{
        padding:0 2px;
      }

      .keywords{

        display: flex;
        justify-content: flex-start;
        /* border:1px solid red; */
        border-radius: 10px;
        padding-left:10px;
        padding-right:10px;
        background-color: white;
        input{
          border:none;
          margin:0;
          font-size: 1.25rem;
          padding-right:10px;
         /*  width: 100%; */
        }
      }


      .categories-list{

        background-color: transparent;
        border:none;
        padding: 0 2px;
        margin: 0;

        .categories-list-inner{
          padding: 0;
          margin: 0;
        }

      }

      .categorey{

        .btn:focus {
          outline: none !important;
          box-shadow: none;
        }

      }

      .category-active{
        border:1px solid #b9b9b9;
        border-radius: 10px;
        background-color: #efefef;
        background-color: white;
        padding-top:8px;
        /* padding:6px; */
      }

    }

    .col-tags{


      .show-results{
        padding: 0;
        margin: 0;
        width: 100%;
        margin-top: 10px;
        button {
          width: 100%;
          background-color: #339da2;
          color:white;
          border-radius: 19px;
          line-height: 3;
          font-size: 25px;
          margin: 0;
          padding: 0;
        }
      }

    }
  }
}
