.modal-tutorial{
  max-width:980px !important;
  .modal-content{
    background-color: transparent ;
    border:none;
  }

  h3{
    color: #eec7b1;
    font-size: 53px;
    //font-family: 'FrankRuhlLibre-Bold';
    font-family: 'Mont Bold';
    margin-bottom: 20px;
  }

  P{
    //font-family: 'FrankRuhlLibre';
    color:white;
    font-size:25px;
    padding: 30px 50px;
  }


  .close-button{
    position: fixed;
    right:40px;
    top:40px;

    color: white;

    .label{
      margin-right:10px;

      display: inline-block;
    }
  }

}


@media (max-width: 576px) {

  .modal-tutorial{
    max-width:100% !important;


    h3{
      font-size: 17px;
      margin-bottom: 6px;
    }

    P{
      font-size:15px;
      padding: 0;
    }


    .close-button{

      right:20px;
      top:20px;

      .label{
        display: none;
      }
    }

  }
}

