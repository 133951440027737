.main-container{
  height: 100vh;
}


.contianer-layout2{
  height: 100%;

  .row{

    height: 100%;
    .col-left{
      background-color: #432d5c;
      color:white;
      /* padding:60px 120px 0; */
      justify-content:center;
      align-items:center;

      display: flex;
      .login-form{
        .container{
          padding: 0;
        }
      }
    }

    .col-right{
      background-color: #efefef;

      justify-content:center;
      align-items:center;

      display: flex;
    }

  }
}

.reverse{
  .row{


    .col-left{
      background-color: #e6e6e6;
      color: black;
    }

    .col-right{
      background-color: #432d5c
    }
  }
}