.sidenav {

  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 290px; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #402b58; // #111; /* Black */
  overflow-x: hidden; /* Disable horizontal scroll */
  padding:0;
  /* padding-top: 20px; */
  /* padding:20px; */

  color:white;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ul{
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .sidenav-top{
    /* height: 177px; */
  }


  .sidenav-bottom{

    margin-top:12px;
    /* min-height:150px; */
    /* height:100%; */
    /* vertical-align: bottom; */
    /* position: relative; */


    .sidenav-copyright{
      padding-right:25px;
      padding-left:25px;

     /*  position: absolute;
      bottom: 0; */

      p.copyright{
        font-size: 12px;
        opacity: 0.4;
      }

      a{
        display: inline-block;
      }
    }

  }
}




/* The navigation menu links */
.sidenav a {
  text-decoration: none;
  font-size: 14px;
  color:white;
  display: block;
  letter-spacing: 0.12px;
  font-weight: normal;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #f1f1f1;
  //color: #818181;
}

/* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}


.sidenav .sidenav-logo{

  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  height: 135px;

  a.logo .word-partner,a.logo .word-zone{
    font-size: 31px;
  }
  a.logo img{
    /* margin-left: -5px; */
    /* width: 78px; */
    opacity: 1;
  }


}



.sidenav  .sidenav-propmo{
  padding-left: 25px;
  font-size: 17px;
  /* height: 60px; */
  background-image: linear-gradient(to bottom, #181617, rgba(71, 69, 71, 1));
  //background-color: #302e2f;
  padding: 19px 5px 10px 5px;
  color: #fff;
  overflow-wrap: break-word;
  object-fit: contain;
  line-height: 1;
  a{
    /* padding-left: 25px;
    line-height: 39px; */
    display: inline;
    font-size: 17px;
  }

  .propmo-new{
    color:#ffbd98;
    font-weight:bold;
    font-size: 17px;
    margin-bottom: 0.7rem;
  }


  a.propmo-btn{
    color:black;
    /* width: 151px; */
    height: 25px;
    margin: 4px 0 6px 0;
    padding: 3px 30px 2px 16px;
    border-radius: 12px;
    background-color: #ffbd98;
    background-image: url('/images/arrow-right.svg') ;
    background-position: 114px center;
    background-repeat: no-repeat;
    //font-weight: normal;
    line-height: normal;
    font-size: 15px;
    display: inline-block;
  }
}


.sidenav  .sidenav-products{
  /* border :1px solid green; */
  background-color: #251832;
  padding-left: 0;
  padding-right: 0;
  padding-top: 8px;
  padding-bottom:12px;
  position: relative;

  h5{
    /* padding-top: 8px; */
    padding-left: 25px;
    padding-bottom: 0;
    margin-bottom: 0;
    font-size:22px;
    letter-spacing: 0.11px;
    line-height: 48px;
    font-family: 'Mont Bold';
  }

  a{
    padding-left: 25px;
    line-height: 39px;
  }

}


.sidenav .products {
  padding-right:6px;
  .inner{
    height:380px;
    overflow: scroll;
    overflow-x: hidden;


    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: #808080; // rgba(90, 90, 90);
      border-radius: 5px;

    }

    &::-webkit-scrollbar-track {
      background: transparent; // rgba(0, 0, 0, 0.2);
    }
    .mask {
      /* height: 50px;
      position: absolute;
      bottom: 0px;
      width: 100%;
      opacity: 0.5;
      background-color: #251832; */
    }

  }

  ul{
    //font-family: "MyriadPro-Light";
    list-style: none;
    margin: 0;
    padding: 0;


    li.active{
      background-color: white;
      border-radius: 19px;
      margin-left: 12px;
      margin-right: 6px;

      a{
        color:black;
        font-size: 16px;
        padding-left:13px;
        //font-family: "MyriadPro-Semibold";
        font-weight: 300;
        letter-spacing: -1.03px;
      }
    }
  }
}

.sidenav  .sidenav-links{
  padding-left:25px;
  padding-top:6px;
  a{
    line-height: 39px;
  }
  a:hover{
    text-decoration: underline;
  }
}


.sidenav .sidenav-admin{
  padding-left:25px;
  a{
    display: inline-block;
  }

  .spinner-border {

    width: 12px;
    height: 12px;
  }

  .btn-reload{
    padding:3px;
    margin-top: 0;
  }

}



.btn-wide{
  width:100%;
  padding-top:20px !important;
  padding-bottom:20px !important;
}

.sidenav-public{
  margin-left:25px;
}

.footer-actions{
  display: flex;
  justify-content:space-between;

  @media (max-width: 768px) {
    flex-direction: column;

    .btn-logout{
      text-align: left;
      padding: 8px 0 8px 0;
    }
  }
  a.profile{
    font-weight: 500;
    font-size:18px;
    //font-family: 'MyriadPro';
  }
  a:hover{
    text-decoration: underline;
  }
  img{
    opacity: 0.5;
    width: 17px;
    height: 17px;
  }
}

.navbar{
  padding:0 !important;

}


.social-media{
  /* border:1px solid red; */
  display: flex;
  justify-content:space-between;

  a.btn-round{
    background-color: #251833;
    border-radius: 18px;
    width:36px;
    height:36px;
    padding:auto;
    text-align: center;
    position: relative;

    img{
      transform: translate3d(-50%, -50% , 0);
      left: 50%;
      top: 50%;
      position: absolute;
    }
  }
}