
a.logo{
  text-decoration: none;

  img{
    padding-bottom: 22px;
    
    opacity: 0.43;
  }

  .word-partner{
    //color:#eec7b1;
    color:#ffbd98;
    //font-family: 'FrankRuhlLibre-Bold', serif;
    font-family: 'Mont Bold';
    font-size: 47px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 31px;
    letter-spacing: normal;
    text-decoration: none;

  }

  .word-zone{
    color:white;
    font-size: 47px;
    font-family: 'Mont Light', sans-serif;
    font-weight: 100;
    font-style: normal;
    font-stretch: normal;
    text-decoration: none;

  }
}

a.logo-mobile{
  text-decoration: none;

  img{
    padding-bottom: 6px;
    /* width: 118px; */
    /* opacity: 0.43; */
  }

  .word-partner{
    color:#eec7b1;
    //font-family: 'FrankRuhlLibre-Bold', serif;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 31px; */
    letter-spacing: normal;

    text-decoration: none;
    display: inline-block;
  }

  .word-zone{
    color:white;
    font-size: 25px;
    //font-family: 'MyriadPro-Light', sans-serif;
    font-weight: 100;
    font-style: normal;
    font-stretch: normal;
    text-decoration: none;
    display: inline-block;
  }
}


a.logo-dark{
  text-decoration: none;

  color:#7e6895;
  font-size: 33px;

  img{
    padding-top:10px;
  }
  span{
    width:3px;
    border-right:1px solid #7e6895;
    padding-left:20px;
  }
  div{
    display: inline-block;
    color:black;
    font-stretch: normal;
    font-style: normal;
    text-decoration: none;
    letter-spacing: normal;
  }
  .word-partner{

    //font-family: 'FrankRuhlLibre-Bold', serif;
    font-size: 33px;
    font-weight: 500;

    /* line-height: 30px; */


    padding-left:20px;
  }

  .word-zone{
    font-size: 33px;
    //font-family: 'MyriadPro-Light', sans-serif;
    font-weight: 100;

  }

}