.table-files{

  border-bottom-width: 0px !important;
  a{
    text-decoration: none;
    color: black;
    font-size:20px;

    &:hover{
      color:#2b28e8;
    }
  }

  thead{

    th{
      color: #656565;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
    }

    th.label{
      color:#7E49B7;
    }
  }

  tbody{


    tr{

      &:hover{
        background:#e3e3e3;
        border-radius: 14px;
      }

      td{
        vertical-align: middle;
        border-bottom-width: 0;
        padding: 1rem 0.5rem;
      }
    }
    .time {
      color: #818181;
      }
  }
  .table > :not(caption) > * > *{
    border-bottom-width: inherit;
    padding: 0.8rem 0.8rem;
  }

  
}

div.export-to-excel{
  border-top: 1px solid #212529;
  margin-top: 3rem;
  padding-top: 3rem;
  padding-right: 6rem;
  display: flex;
  justify-content: flex-end;

  button {
    border-radius: 12px; 
    background-color: #ffbd98;
    padding: 6px 16px;
    display: inline-block;
    border: none;
  }
}


@media (max-width: 768px) {

  .table-files{

    a{
      font-size:18px;
    }
  }
}